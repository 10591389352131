<!-- Placeholder -->
<div class="home-placeholder" *ngIf="!showBanner">
    <div class="home-placeholder__banner">
        <img class="def" src="assets/images/default/img_def.svg">
        <div class="hoverEffect"></div>
    </div>
    <div class="home-placeholder__section">
        <div class="title"></div>
        <div class="subtitle"></div>
        <div class="items">
            <div class="item" *ngFor="let item of array(16);">
                <div class="item__img"></div>
                <div class="item__title"></div>
            </div>
        </div>
    </div>
</div>


<!-- Banner -->
<app-home-banner [hidden]="!showBanner" *ngIf="banners && banners.length > 0" [banners]="banners"
    (bannerReadyE)="showBanner = true; showCategories = true; showRoadshow = true;" (clickBannerE)=onOfferClick($event)>
</app-home-banner>
<!-- Categories -->
<div [hidden]="!showCategories" class="categories-home-container">
    <div class="header">
        <div class="title">Featured Categories</div>
        <div class="sub-title">Ready for you to browse through</div>
    </div>
    <div class="category-cards">
        <div *ngFor="let category of categories" class="category-card">
            <img class="img-cover" src="{{category?.imageUrls.thumbnail | img:'png'}}"
                (click)="onCategoryClick(category.id)">
            <div class="data">
                <div class="title">{{ category.title }}</div>
                <div class="counter">{{ category.numberOfOffers }} Offers</div>
            </div>
        </div>
    </div>
    <div class="category-cards-small">
        <div class="category-card-small" *ngFor="let category of categories" (click)="onCategoryClick(category.id)">{{
            category.title }}</div>
    </div>
    <div class="footer">
        <button [routerLink]="'/categories'" class="btn-blue">See All Categories</button>
    </div>
</div>
<!-- Roadshow -->
<div class="roadshow-home-container" *ngIf="showRoadshow">
    <div class="header">
        <div class="title">Roadshows</div>
        <div class="sub-title">Special Offers on 9 Locations</div>
    </div>
    <div class="content">
        <div class="bannerRs">
            <div class="bannerRs__data">
                <div class="year">{{currentYear}}</div>
                <div class="title">Roadshow Map</div>
                <div class="subtitle">Check Out Roadshow Offer in Abu Dhabi</div>
            </div>
            <div class="bannerRs__content">
                <div class="text">
                    We are pleased to announce that the Mazaya Roadshow has re-opened in line with Government Covid-19
                    safety guidelines.
                    Visit us at any of our locations to learn more about the available promotional offers.
                </div>
                <div class="footer">
                    <button class="btn btn-white" (click)="onRoadshowClick()">
                        See All Roadshows Offers
                    </button>
                </div>

            </div>


        </div>
    </div>
</div>
<!-- Collections -->
<app-home-collections [hidden]="!showCollections" *ngIf="collections && collections.length > 0"
    [collections]="collections" (bannerReadyE)="showCollections = true; showOffers = true"
    (clickBannerE)="onCollectionClick($event)"> </app-home-collections>
<!-- Last Offers -->
<div class="offers-home-container" [hidden]="!showOffers" *ngIf="latestOffers">
    <div class="header">
        <div class="title">Latest Offers</div>
    </div>
    <div class="content">
        <div class="offer-card" *ngFor="let offer of latestOffers">
            <div class="cover">
                <img class="img-cover" [defaultImage]="'assets/images/default/img_def.svg'"
                    lazyLoad="{{offer.mainImage | img}}" [routerLink]="'/offers/' + offer.id">
            </div>
            <div class="data">
                <div class="sub-title">
                    <div class="category">{{offer?.categories[0]?.title | defaultValue: 'No category'}}</div>
                    <div class="location">{{offer?.city | defaultValue: 'No locations'}}</div>
                </div>
                <div class="company-name" [routerLink]="'/offers/' + offer.id"> {{offer?.companyNameEnglish |
                    defaultValue: 'The title is not defined'}}</div>
                <div class="title" *ngIf="offer?.companyNameEnglish != offer?.title"
                    [routerLink]="'/offers/' + offer.id"> {{offer?.title | defaultValue: 'The title is not defined'}}
                </div>
                <div class="price">
                    <ng-container *ngIf="offer.type == 'Price'">
                        <span class="bold">AED {{offer.priceFrom | number: '1.0' }} - AED {{offer.priceTo | number:
                            '1.0'}}</span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'Discount'">
                        <span *ngIf="offer.discountFrom" class="bold">From {{offer.discountFrom}}% - To
                            {{offer.discountTo}}% Discount</span>
                        <span *ngIf="offer.discount" class="discount">{{offer.discount}}% OFF </span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'Other'">
                        <span class="bold">{{offer.priceCustom}}</span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'Discount Price'">
                        <span class="line-through">AED {{offer.originalPrice | number: '1.0'}}</span> <span
                            class="bold">AED {{offer.discountedPrice | number: '1.0'}}</span>
                    </ng-container>
                </div>
            </div>

            <div class="tag" *ngIf="offer?.tags[0] && offer?.tags[0].title != 'Latest'">
                {{ offer.tags[0].title }}
            </div>
        </div>
    </div>
    <div class="footer">
        <button *ngIf="latestOffers?.length > 0" class="btn-blue" [routerLink]="'/offers'">See All Offers</button>
    </div>
</div>